import React from 'react';

const Languages = () => {
  return (
    <div className='skills__content'>
      <h3 className='skills__title'>Languages</h3>
      <div className='skills__box'>
        <div className='skills__group'>
          <div className='skills__data'>
            <svg viewBox='0 0 128 128'>
              <path fill='#fff' d='M22.67 47h99.67v73.67H22.67z'></path>
              <path
                data-name='original'
                fill='#007acc'
                d='M1.5 63.91v62.5h125v-125H1.5zm100.73-5a15.56 15.56 0 017.82 4.5 20.58 20.58 0 013 4c0 .16-5.4 3.81-8.69 5.85-.12.08-.6-.44-1.13-1.23a7.09 7.09 0 00-5.87-3.53c-3.79-.26-6.23 1.73-6.21 5a4.58 4.58 0 00.54 2.34c.83 1.73 2.38 2.76 7.24 4.86 8.95 3.85 12.78 6.39 15.16 10 2.66 4 3.25 10.46 1.45 15.24-2 5.2-6.9 8.73-13.83 9.9a38.32 38.32 0 01-9.52-.1 23 23 0 01-12.72-6.63c-1.15-1.27-3.39-4.58-3.25-4.82a9.34 9.34 0 011.15-.73L82 101l3.59-2.08.75 1.11a16.78 16.78 0 004.74 4.54c4 2.1 9.46 1.81 12.16-.62a5.43 5.43 0 00.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48 16.48 0 01-3.43-6.25 25 25 0 01-.22-8c1.33-6.23 6-10.58 12.82-11.87a31.66 31.66 0 019.49.26zm-29.34 5.24v5.12H56.66v46.23H45.15V69.26H28.88v-5a49.19 49.19 0 01.12-5.17C29.08 59 39 59 51 59h21.83z'></path>
            </svg>
            <h3 className='skills__name'>TypeScript</h3>
          </div>
          <div className='skills__data'>
            <svg viewBox='0 0 128 128'>
              <defs>
                <path id='go-original-a' d='M18.8 1h90.5v126H18.8z'></path>
              </defs>
              <clipPath id='go-original-b'>
                <use xlinkHref='#go-original-a' overflow='visible'></use>
              </clipPath>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#F6D2A2'
                d='M21.1 68.7c.2 3.5 3.7 1.9 5.3.8 1.5-1.1 2-.2 2.1-2.3.1-1.4.2-2.7.2-4.1-2.3-.2-4.8.3-6.7 1.7-.9.7-2.8 3-.9 3.9'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M23 71.2c-.7 0-2-.3-2.2-2.3-.6-.4-.8-.9-.8-1.2-.1-1.2 1.2-2.6 1.9-3.1 1.6-1.2 3.7-1.8 5.9-1.8h1.3v.3c.1 1.1 0 2.2-.1 3.2 0 .3 0 .6-.1.9-.1 1.5-.4 1.7-1.1 2-.3.1-.6.2-1.1.6-.5.3-2.2 1.4-3.7 1.4zm4.8-7.8c-2.1 0-4 .6-5.5 1.7-.7.5-1.7 1.7-1.6 2.5 0 .3.2.6.6.8l.2.1v.2c.1 1.6.9 1.8 1.5 1.8 1 0 2.4-.7 3.3-1.3.6-.4 1-.5 1.3-.6.5-.2.6-.2.7-1.4 0-.3 0-.6.1-.9.1-.9.1-1.9.1-2.8-.3-.1-.5-.1-.7-.1z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#C6B198'
                d='M21.1 68.7c.5-.2 1.1-.3 1.4-.8'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M21.1 69c-.1 0-.3-.1-.3-.2-.1-.2 0-.4.2-.4.1 0 .2-.1.2-.1.4-.2.8-.3 1-.6.1-.1.3-.2.5-.1.1.1.2.3.1.5-.4.5-.9.7-1.3.8l-.2.1h-.2z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#6AD7E5'
                d='M29.3 26.4c-13.6-3.8-3.5-21.1 7.4-14l-7.4 14z'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M29.5 26.8l-.3-.1c-7-2-6.9-7-6.7-8.5.5-3.8 4.1-7.8 8.9-7.8 1.9 0 3.7.6 5.5 1.8l.3.2-7.7 14.4zm1.9-15.7c-4.5 0-7.8 3.7-8.3 7.2-.5 3.6 1.7 6.4 6 7.7l7.1-13.5c-1.5-.9-3.1-1.4-4.8-1.4z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#6AD7E5'
                d='M89.6 11.1c10.7-7.5 20.5 9.5 8 13.8l-8-13.8z'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M97.5 25.3L89.2 11l.3-.2c1.9-1.3 3.8-2 5.7-2 4.6 0 7.9 3.8 8.6 7.5.3 1.5.6 6.6-6 8.8l-.3.2zm-7.4-14l7.7 13.3c3.9-1.4 5.9-4.4 5.3-8-.6-3.4-3.7-6.9-7.9-6.9-1.7-.1-3.4.4-5.1 1.6z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#F6D2A2'
                d='M92 112.3c2.7 1.7 7.7 6.8 3.6 9.3-3.9 3.6-6.1-4-9.6-5 1.5-2 3.4-3.9 6-4.3'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M93.5 122.9c-1.6 0-3-1.6-4.2-3.1-1.1-1.2-2.2-2.5-3.4-2.9l-.5-.1.3-.4c1.2-1.7 3.2-3.9 6.2-4.4h.1l.1.1c1.7 1.1 5.4 4.2 5.3 7.1 0 1.1-.6 2-1.7 2.7-.7.7-1.4 1-2.2 1zm-7-6.5c1.2.5 2.2 1.8 3.2 2.9 1.2 1.5 2.4 2.8 3.7 2.8.6 0 1.2-.3 1.8-.9h.1c.9-.6 1.4-1.3 1.4-2.2 0-2.3-2.9-5.2-4.9-6.5-1.8.5-3.6 1.7-5.3 3.9zm9.1 5.5c-.1 0-.2-.1-.3-.2-.2-.4-.4-.9-.5-1.3-.3-.8-.6-1.6-1.2-2.2-.1-.1-.1-.3 0-.5.1-.1.3-.1.5 0 .7.7 1.1 1.6 1.4 2.5l.5 1.2c.1.2 0 .4-.1.5h-.3z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#F6D2A2'
                d='M43.2 118.1c-3.2.5-5 3.4-7.7 4.9-2.5 1.5-3.5-.5-3.7-.9-.4-.2-.4.2-1-.4-2.3-3.7 2.4-6.4 4.9-8.2 3.5-.8 5.7 2.2 7.5 4.6'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M33.8 123.8c-1.3 0-2-1.1-2.2-1.5h-.1c-.3 0-.5-.1-.9-.5v-.1c-2.2-3.5 1.6-6.2 4.1-8l.9-.6h.2c.4-.1.7-.1 1.1-.1 3 0 4.9 2.6 6.5 4.7l.5.7-.6.1c-1.9.3-3.3 1.5-4.7 2.7-.9.8-1.8 1.5-2.8 2.1-.8.3-1.4.5-2 .5zm-2.2-2.1c.1 0 .2 0 .4.1h.1l.1.1c.2.3.7 1.2 1.7 1.2.5 0 1-.2 1.5-.5 1-.5 1.9-1.3 2.7-2 1.3-1.1 2.7-2.3 4.5-2.8-1.5-2-3.3-4.2-5.8-4.2-.3 0-.6 0-.9.1l-.8.6c-2.6 1.8-5.8 4.1-3.9 7.1.1.2.2.3.4.3zm.2.7c-.2 0-.4-.2-.3-.4.1-1 .6-1.7 1.1-2.5.3-.4.5-.8.7-1.2.1-.2.3-.2.4-.2.2.1.2.3.2.4-.2.5-.5.9-.8 1.3-.5.7-.9 1.3-1 2.1 0 .4-.1.5-.3.5z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M29.9 21.7c-1.8-.9-3.1-2.2-2-4.3 1-1.9 2.9-1.7 4.7-.8l-2.7 5.1zm64.9-1.8c1.8-.9 3.1-2.2 2-4.3-1-1.9-2.9-1.7-4.7-.8l2.7 5.1z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#F6D2A2'
                d='M107.1 68.2c-.2 3.5-3.7 1.9-5.3.8-1.5-1.1-2-.2-2.1-2.3-.1-1.4-.2-2.7-.2-4.1 2.3-.2 4.8.3 6.7 1.7 1 .8 2.8 3 .9 3.9'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M105.3 70.7c-1.5 0-3.2-1.1-3.7-1.4-.5-.3-.8-.5-1.1-.6-.8-.3-1-.5-1.1-2 0-.3 0-.6-.1-.9-.1-1-.2-2.1-.1-3.2v-.3h1.3c2.2 0 4.3.6 5.9 1.8.7.5 2 1.9 1.9 3.1 0 .4-.2.9-.8 1.2-.2 2-1.5 2.3-2.2 2.3zM99.8 63c0 .9 0 1.9.1 2.8 0 .3 0 .6.1.9.1 1.2.2 1.2.7 1.4.3.1.7.3 1.3.6.9.6 2.3 1.3 3.3 1.3.6 0 1.4-.2 1.5-1.8V68l.2-.1c.4-.2.6-.4.6-.8.1-.8-.9-2-1.6-2.5-1.5-1.1-3.5-1.7-5.5-1.7-.2.1-.4.1-.7.1z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#C6B198'
                d='M107.1 68.2c-.5-.2-1.1-.3-1.4-.8'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M107.1 68.6h-.1l-.2-.1c-.5-.2-1-.3-1.3-.8-.1-.1-.1-.4.1-.5.1-.1.4-.1.5.1.2.3.6.4 1 .6.1 0 .2.1.2.1.2.1.3.3.2.4-.1.1-.3.2-.4.2z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#6AD7E5'
                d='M62.8 4c13.6 0 26.3 1.9 33 15 6 14.6 3.8 30.4 4.8 45.9.8 13.3 2.5 28.6-3.6 40.9-6.5 12.9-22.7 16.2-36 15.7-10.5-.4-23.1-3.8-29.1-13.4-6.9-11.2-3.7-27.9-3.2-40.4.6-14.8-4-29.7.9-44.1C34.5 8.5 48.1 5.1 62.8 4'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M63.3 121.9h-2.5c-4.1-.1-10.3-.8-16.4-3.3-5.9-2.4-10.2-5.8-13-10.3-5.6-9.1-4.6-21.6-3.7-32.7.2-2.8.4-5.4.5-7.9.2-5.2-.2-10.6-.7-15.7-.8-9.4-1.6-19.1 1.5-28.5 2.4-7 6.7-12 13.2-15.2 5.1-2.5 11.4-3.9 20.4-4.6C76 3.6 89.3 5.5 96 18.8c4.4 10.7 4.4 22.2 4.5 33.3 0 4.2 0 8.5.3 12.7.1 1.3.2 2.6.2 3.9.8 12.2 1.7 26-3.9 37.2-2.8 5.7-7.7 9.9-14.4 12.6-5.4 2.2-12.2 3.4-19.4 3.4zM62.8 4.3c-14.1 1.1-27.9 4.2-33 19.4-3.1 9.3-2.3 18.9-1.5 28.2.4 5.2.9 10.5.7 15.8-.1 2.5-.3 5.1-.5 7.9-.9 11-1.9 23.4 3.6 32.3 2.3 3.7 9.7 12.5 28.8 13.2h2.5c22.1 0 30.3-9.8 33.3-15.6 5.5-11 4.6-24.8 3.9-36.9-.1-1.3-.2-2.6-.2-3.9-.2-4.2-.3-8.5-.3-12.7-.1-11-.1-22.5-4.4-33.1C92.7 13 88.2 9 82 6.7c-6.4-2.1-13.6-2.4-19.2-2.4z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#fff'
                d='M65.2 22.2c2.4 14.2 25.6 10.4 22.3-3.9-3-12.8-23.1-9.2-22.3 3.9'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M76.2 31.5c-4.5 0-10.2-2.4-11.4-9.2-.2-3.2.8-6.1 2.9-8.3 2.3-2.5 5.8-3.9 9.4-3.9 4.2 0 9.2 2.2 10.6 8.3.8 3.4.2 6.4-1.7 8.8-2.1 2.6-5.8 4.3-9.8 4.3zm-10.7-9.3c.5 2.8 1.8 5 3.9 6.6 1.8 1.4 4.3 2.1 6.8 2.1 3.7 0 7.3-1.6 9.3-4.1 1.8-2.2 2.3-5.1 1.6-8.3-1.3-5.7-6-7.7-10-7.7-3.4 0-6.7 1.4-8.9 3.7-1.9 2-2.9 4.7-2.7 7.7z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#fff'
                d='M37.5 24.5c3.2 12.3 22.9 9.2 22.2-3.2-.9-14.8-25.3-12-22.2 3.2'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M48 32.7c-4.3 0-9.3-2.1-10.9-8.1-.7-3.5 0-6.7 2-9.1 2.2-2.7 5.8-4.3 9.7-4.3 5.2 0 10.7 3.1 11.1 10.1.2 2.9-.7 5.5-2.7 7.6-2.1 2.3-5.6 3.8-9.2 3.8zm.8-20.8c-3.7 0-7.1 1.5-9.2 4-1.9 2.3-2.5 5.2-1.8 8.5C39.2 30 44 32 48 32c3.4 0 6.7-1.3 8.8-3.6 1.8-1.9 2.7-4.4 2.5-7.1-.2-4.3-3.1-9.4-10.5-9.4z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#fff'
                d='M68 39.2c0 1.8.4 3.9.1 5.9-.5.9-1.4 1-2.2 1.3-1.1-.2-2-.9-2.5-1.9-.3-2.2.1-4.4.2-6.6l4.4 1.3z'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M65.9 46.8c-1.3-.2-2.3-1-2.8-2.1-.2-1.6-.1-3.1 0-4.6.1-.7.1-1.4.1-2.1v-.4l5.1 1.6v.2c0 .6.1 1.2.1 1.9.1 1.3.2 2.7 0 4v.1c-.4.8-1.1 1-1.8 1.3-.2-.1-.4 0-.7.1zm-2.2-2.4c.4.9 1.2 1.5 2.1 1.7.2-.1.4-.1.5-.2.6-.2 1.1-.4 1.4-.9.2-1.2.1-2.5 0-3.8 0-.6-.1-1.2-.1-1.7l-3.8-1.2c0 .6-.1 1.2-.1 1.7-.1 1.6-.2 3 0 4.4z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M46.3 22.5c0 2-1.5 3.6-3.3 3.6-1.8 0-3.3-1.6-3.3-3.6s1.5-3.6 3.3-3.6c1.8 0 3.3 1.6 3.3 3.6'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#fff'
                d='M45.2 23.3c0 .5-.4.9-.8.9s-.8-.4-.8-.9.4-.9.8-.9c.5 0 .8.4.8.9'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M74.2 21.6c0 2-1.5 3.6-3.3 3.6-1.8 0-3.3-1.6-3.3-3.6s1.5-3.6 3.3-3.6c1.8 0 3.3 1.6 3.3 3.6'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#fff'
                d='M73.2 22.4c0 .5-.3.9-.8.9-.4 0-.8-.4-.8-.9s.3-.9.8-.9c.4 0 .8.4.8.9M58.4 39c-1.5 3.5.8 10.6 4.8 5.4-.3-2.2.1-4.4.2-6.6l-5 1.2z'
                clip-path='url(#go-original-b)'></path>
              <path
                d='M60.5 46.6c-.7 0-1.4-.4-1.9-1.2-1.1-1.6-1.3-4.6-.5-6.5l.1-.2 5.5-1.4v.4l-.1 2.2c-.1 1.5-.2 2.9 0 4.4v.1l-.1.1c-1 1.4-2 2.1-3 2.1zm-1.8-7.3c-.6 1.7-.4 4.4.5 5.7.4.6.8.9 1.3.9.7 0 1.5-.6 2.3-1.6-.2-1.5-.1-3 .1-4.4l.1-1.7-4.3 1.1z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                fill='#F6D2A2'
                d='M58.9 32.2c-2.7.2-4.9 3.5-3.5 6 1.9 3.4 6-.3 8.6 0 3 .1 5.4 3.2 7.8.6 2.7-2.9-1.2-5.7-4.1-7l-8.8.4z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill='#231F20'
                d='M69.7 40.2c-.9 0-1.8-.4-2.7-.8-.9-.4-1.9-.8-3-.8h-.3c-.8 0-1.7.3-2.7.7-1.1.4-2.2.7-3.2.7-1.2 0-2.1-.5-2.7-1.6-.7-1.2-.6-2.6.1-3.9.8-1.5 2.2-2.4 3.7-2.6l8.9-.4h.1c2.2.9 4.7 2.6 5.2 4.6.2 1-.1 2-.9 2.9-.8.9-1.6 1.2-2.5 1.2zM64.1 38c1.1 0 2.2.5 3.2.9.9.4 1.7.7 2.5.7.7 0 1.3-.3 1.9-.9.7-.7.9-1.5.8-2.3-.4-1.7-2.8-3.3-4.7-4.1l-8.7.4c-1.3.1-2.5 1-3.2 2.2-.6 1.1-.6 2.3-.1 3.3.5.9 1.1 1.3 2.1 1.3.9 0 1.9-.4 2.9-.7 1.1-.4 2-.7 3-.7 0-.2.1-.2.3-.1z'
                clip-path='url(#go-original-b)'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M58.6 32.1c-.2-4.7 8.8-5.3 9.8-1.4 1.1 4-9.4 4.9-9.8 1.4'
                clip-path='url(#go-original-b)'></path>
            </svg>
            <h3 className='skills__name'>Go</h3>
          </div>
          <div className='skills__data'>
            <svg viewBox='0 0 128 128'>
              <linearGradient
                id='ruby-original-a'
                gradientUnits='userSpaceOnUse'
                x1='157.08'
                y1='2382.05'
                x2='131.682'
                y2='2426.892'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#FB7655'></stop>
                <stop offset='0' stop-color='#FB7655'></stop>
                <stop offset='.41' stop-color='#E42B1E'></stop>
                <stop offset='.99' stop-color='#900'></stop>
                <stop offset='1' stop-color='#900'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-a)'
                d='M97.078 83.214L28.34 124.031l89.003-6.04 6.855-89.745z'></path>
              <linearGradient
                id='ruby-original-b'
                gradientUnits='userSpaceOnUse'
                x1='169.731'
                y1='2419.72'
                x2='136.998'
                y2='2441.685'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#871101'></stop>
                <stop offset='0' stop-color='#871101'></stop>
                <stop offset='.99' stop-color='#911209'></stop>
                <stop offset='1' stop-color='#911209'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-b)'
                d='M117.488 117.93l-7.649-52.799-20.837 27.514z'></path>
              <linearGradient
                id='ruby-original-c'
                gradientUnits='userSpaceOnUse'
                x1='143.542'
                y1='2380.69'
                x2='110.81'
                y2='2402.655'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#871101'></stop>
                <stop offset='0' stop-color='#871101'></stop>
                <stop offset='.99' stop-color='#911209'></stop>
                <stop offset='1' stop-color='#911209'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-c)'
                d='M117.592 117.93l-56.044-4.399-32.91 10.385z'></path>
              <linearGradient
                id='ruby-original-d'
                gradientUnits='userSpaceOnUse'
                x1='74.817'
                y1='2435.622'
                x2='79.891'
                y2='2402.644'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='.23' stop-color='#E57252'></stop>
                <stop offset='.46' stop-color='#DE3B20'></stop>
                <stop offset='.99' stop-color='#A60003'></stop>
                <stop offset='1' stop-color='#A60003'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-d)'
                d='M28.717 123.928l14.001-45.867-30.81 6.588z'></path>
              <linearGradient
                id='ruby-original-e'
                gradientUnits='userSpaceOnUse'
                x1='109.719'
                y1='2466.413'
                x2='111.589'
                y2='2432.757'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='.23' stop-color='#E4714E'></stop>
                <stop offset='.56' stop-color='#BE1A0D'></stop>
                <stop offset='.99' stop-color='#A80D00'></stop>
                <stop offset='1' stop-color='#A80D00'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-e)'
                d='M88.996 92.797l-12.882-50.46-36.866 34.558z'></path>
              <linearGradient
                id='ruby-original-f'
                gradientUnits='userSpaceOnUse'
                x1='140.691'
                y1='2497.523'
                x2='146.289'
                y2='2473.401'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='.18' stop-color='#E46342'></stop>
                <stop offset='.4' stop-color='#C82410'></stop>
                <stop offset='.99' stop-color='#A80D00'></stop>
                <stop offset='1' stop-color='#A80D00'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-f)'
                d='M121.275 43.047L86.426 14.585l-9.704 31.373z'></path>
              <linearGradient
                id='ruby-original-g'
                gradientUnits='userSpaceOnUse'
                x1='123.6'
                y1='2506.018'
                x2='147.719'
                y2='2518.077'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='.54' stop-color='#C81F11'></stop>
                <stop offset='.99' stop-color='#BF0905'></stop>
                <stop offset='1' stop-color='#BF0905'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-g)'
                d='M104.978 4.437L84.481 15.764 71.551 4.285z'></path>
              <linearGradient
                id='ruby-original-h'
                gradientUnits='userSpaceOnUse'
                x1='53.674'
                y1='2444.028'
                x2='55.66'
                y2='2424.153'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='0' stop-color='#fff'></stop>
                <stop offset='.31' stop-color='#DE4024'></stop>
                <stop offset='.99' stop-color='#BF190B'></stop>
                <stop offset='1' stop-color='#BF190B'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-h)'
                d='M3.802 100.034l8.586-15.659L5.442 65.72z'></path>
              <path
                fill='#fff'
                d='M4.981 65.131l6.987 19.821 30.365-6.812L77 45.922l9.783-31.075L71.38 3.969l-26.19 9.802c-8.252 7.675-24.263 22.86-24.84 23.146-.573.291-10.575 19.195-15.369 28.214z'></path>
              <linearGradient
                id='ruby-original-i'
                gradientUnits='userSpaceOnUse'
                x1='40.026'
                y1='2418.781'
                x2='133.345'
                y2='2514.739'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#BD0012'></stop>
                <stop offset='0' stop-color='#BD0012'></stop>
                <stop offset='.07' stop-color='#fff'></stop>
                <stop offset='.17' stop-color='#fff'></stop>
                <stop offset='.27' stop-color='#C82F1C'></stop>
                <stop offset='.33' stop-color='#820C01'></stop>
                <stop offset='.46' stop-color='#A31601'></stop>
                <stop offset='.72' stop-color='#B31301'></stop>
                <stop offset='.99' stop-color='#E82609'></stop>
                <stop offset='1' stop-color='#E82609'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-i)'
                d='M29.519 29.521c17.882-17.73 40.937-28.207 49.785-19.28 8.843 8.926-.534 30.62-18.418 48.345-17.884 17.725-40.653 28.779-49.493 19.852-8.849-8.92.242-31.191 18.126-48.917z'></path>
              <linearGradient
                id='ruby-original-j'
                gradientUnits='userSpaceOnUse'
                x1='111.507'
                y1='2409.102'
                x2='83.398'
                y2='2416.039'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#8C0C01'></stop>
                <stop offset='0' stop-color='#8C0C01'></stop>
                <stop offset='.54' stop-color='#990C00'></stop>
                <stop offset='.99' stop-color='#A80D0E'></stop>
                <stop offset='1' stop-color='#A80D0E'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-j)'
                d='M28.717 123.909l13.89-46.012 46.135 14.82c-16.68 15.642-35.233 28.865-60.025 31.192z'></path>
              <linearGradient
                id='ruby-original-k'
                gradientUnits='userSpaceOnUse'
                x1='159.785'
                y1='2442.837'
                x2='134.814'
                y2='2465.217'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#7E110B'></stop>
                <stop offset='0' stop-color='#7E110B'></stop>
                <stop offset='.99' stop-color='#9E0C00'></stop>
                <stop offset='1' stop-color='#9E0C00'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-k)'
                d='M77.062 45.831l11.844 46.911c13.934-14.65 26.439-30.401 32.563-49.883l-44.407 2.972z'></path>
              <linearGradient
                id='ruby-original-l'
                gradientUnits='userSpaceOnUse'
                x1='168.959'
                y1='2483.901'
                x2='156.521'
                y2='2497.199'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#79130D'></stop>
                <stop offset='0' stop-color='#79130D'></stop>
                <stop offset='.99' stop-color='#9E120B'></stop>
                <stop offset='1' stop-color='#9E120B'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-l)'
                d='M121.348 43.097c4.74-14.305 5.833-34.825-16.517-38.635l-18.339 10.13 34.856 28.505z'></path>
              <path
                fill='#9E1209'
                d='M3.802 99.828c.656 23.608 17.689 23.959 24.945 24.167l-16.759-39.14-8.186 14.973z'></path>
              <radialGradient
                id='ruby-original-m'
                cx='138.703'
                cy='2464.789'
                r='30.601'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'
                gradientUnits='userSpaceOnUse'>
                <stop offset='0' stop-color='#A80D00'></stop>
                <stop offset='0' stop-color='#A80D00'></stop>
                <stop offset='.99' stop-color='#7E0E08'></stop>
                <stop offset='1' stop-color='#7E0E08'></stop>
              </radialGradient>
              <path
                fill='url(#ruby-original-m)'
                d='M77.128 45.904c10.708 6.581 32.286 19.798 32.723 20.041.68.383 9.304-14.542 11.261-22.976l-43.984 2.935z'></path>
              <radialGradient
                id='ruby-original-n'
                cx='96.325'
                cy='2424.465'
                r='40.679'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'
                gradientUnits='userSpaceOnUse'>
                <stop offset='0' stop-color='#A30C00'></stop>
                <stop offset='0' stop-color='#A30C00'></stop>
                <stop offset='.99' stop-color='#800E08'></stop>
                <stop offset='1' stop-color='#800E08'></stop>
              </radialGradient>
              <path
                fill='url(#ruby-original-n)'
                d='M42.589 77.897l18.57 35.828c10.98-5.955 19.579-13.211 27.454-20.983L42.589 77.897z'></path>
              <linearGradient
                id='ruby-original-o'
                gradientUnits='userSpaceOnUse'
                x1='67.509'
                y1='2393.115'
                x2='57.373'
                y2='2427.506'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#8B2114'></stop>
                <stop offset='0' stop-color='#8B2114'></stop>
                <stop offset='.43' stop-color='#9E100A'></stop>
                <stop offset='.99' stop-color='#B3100C'></stop>
                <stop offset='1' stop-color='#B3100C'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-o)'
                d='M11.914 84.904l-2.631 31.331c4.964 6.781 11.794 7.371 18.96 6.842-5.184-12.9-15.538-38.696-16.329-38.173z'></path>
              <linearGradient
                id='ruby-original-p'
                gradientUnits='userSpaceOnUse'
                x1='145.272'
                y1='2507.076'
                x2='167.996'
                y2='2497.045'
                gradientTransform='matrix(1 0 0 -1 -47.5 2517)'>
                <stop offset='0' stop-color='#B31000'></stop>
                <stop offset='0' stop-color='#B31000'></stop>
                <stop offset='.44' stop-color='#910F08'></stop>
                <stop offset='.99' stop-color='#791C12'></stop>
                <stop offset='1' stop-color='#791C12'></stop>
              </linearGradient>
              <path
                fill='url(#ruby-original-p)'
                d='M86.384 14.67l36.891 5.177c-1.969-8.343-8.015-13.727-18.32-15.41L86.384 14.67z'></path>
            </svg>
            <h3 className='skills__name'>Ruby</h3>
          </div>
          <div className='skills__data'>
            <svg viewBox='0 0 128 128'>
              <path
                fill='#0074BD'
                d='M47.617 98.12s-4.767 2.774 3.397 3.71c9.892 1.13 14.947.968 25.845-1.092 0 0 2.871 1.795 6.873 3.351-24.439 10.47-55.308-.607-36.115-5.969zm-2.988-13.665s-5.348 3.959 2.823 4.805c10.567 1.091 18.91 1.18 33.354-1.6 0 0 1.993 2.025 5.132 3.131-29.542 8.64-62.446.68-41.309-6.336z'></path>
              <path
                fill='#EA2D2E'
                d='M69.802 61.271c6.025 6.935-1.58 13.17-1.58 13.17s15.289-7.891 8.269-17.777c-6.559-9.215-11.587-13.792 15.635-29.58 0 .001-42.731 10.67-22.324 34.187z'></path>
              <path
                fill='#0074BD'
                d='M102.123 108.229s3.529 2.91-3.888 5.159c-14.102 4.272-58.706 5.56-71.094.171-4.451-1.938 3.899-4.625 6.526-5.192 2.739-.593 4.303-.485 4.303-.485-4.953-3.487-32.013 6.85-13.743 9.815 49.821 8.076 90.817-3.637 77.896-9.468zM49.912 70.294s-22.686 5.389-8.033 7.348c6.188.828 18.518.638 30.011-.326 9.39-.789 18.813-2.474 18.813-2.474s-3.308 1.419-5.704 3.053c-23.042 6.061-67.544 3.238-54.731-2.958 10.832-5.239 19.644-4.643 19.644-4.643zm40.697 22.747c23.421-12.167 12.591-23.86 5.032-22.285-1.848.385-2.677.72-2.677.72s.688-1.079 2-1.543c14.953-5.255 26.451 15.503-4.823 23.725 0-.002.359-.327.468-.617z'></path>
              <path
                fill='#EA2D2E'
                d='M76.491 1.587S89.459 14.563 64.188 34.51c-20.266 16.006-4.621 25.13-.007 35.559-11.831-10.673-20.509-20.07-14.688-28.815C58.041 28.42 81.722 22.195 76.491 1.587z'></path>
              <path
                fill='#0074BD'
                d='M52.214 126.021c22.476 1.437 57-.8 57.817-11.436 0 0-1.571 4.032-18.577 7.231-19.186 3.612-42.854 3.191-56.887.874 0 .001 2.875 2.381 17.647 3.331z'></path>
            </svg>
            <h3 className='skills__name'>Java</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Languages;
